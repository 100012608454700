var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app flex-row align-items-center"
  }, [_c('div', {
    staticClass: "container mt-3"
  }, [_c('b-row', {
    staticClass: "row justify-content-center"
  }, [_c('b-col', {
    staticClass: "left mt-5 display-mobile",
    attrs: {
      "md": "6",
      "sm": "0"
    }
  }, [_c('img', {
    staticStyle: {
      "height": "75%"
    },
    attrs: {
      "src": require("../../assets/img/design.png"),
      "alt": "admin@bootstrapmaster.com"
    }
  })]), _c('b-col', {
    staticClass: "right",
    attrs: {
      "md": "6"
    }
  }, [_c('b-card-group', [_c('b-card-body', {
    staticClass: "background-mobile"
  }, [_c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    staticClass: "text-center"
  }, [_c('img', {
    staticClass: "m-5",
    attrs: {
      "src": require("../../assets/img/gramedia_digital.png"),
      "alt": "admin@bootstrapmaster.com"
    }
  })])], 1), _c('h1', {
    staticClass: "h2 text-center text-primary"
  }, [_vm._v(" Digital Books Gramedia ")]), _c('h5', {
    staticClass: "text-center text-danger"
  }, [_vm._v(" You are currently using the default password. ")]), _c('h5', {
    staticClass: "text-center text-danger"
  }, [_vm._v(" Please, change your default password with new password. "), _c('p', [_vm._v("And then, Login with new password !")])]), _c('div', [_c('div', [_c('b-form-group', {
    staticClass: "mt-5 mb-0"
  }, [_c('b-form-input', {
    staticClass: "form-control",
    attrs: {
      "type": "password",
      "placeholder": "type new password...",
      "required": ""
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  })], 1)], 1), _c('div', {
    staticClass: "my-3"
  }, [_c('b-col', [_c('b-row', [_c('b-button', {
    staticClass: "px-4 w-100 mt-4",
    attrs: {
      "type": "submit",
      "variant": "primary",
      "disabled": _vm.isDisable
    },
    on: {
      "click": _vm.changePassword
    }
  }, [_vm._v(" Change Password "), _c('i', {
    staticClass: "fa fa-sign-in",
    attrs: {
      "aria-hidden": "true"
    }
  })])], 1)], 1)], 1)])], 1)], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }