<template>
  <div class="app flex-row align-items-center">
    <div class="container mt-3">
      <b-row class="row justify-content-center">
        <b-col md="6" sm="0" class="left mt-5 display-mobile">
          <img
            style="height: 75%"
            src="../../assets/img/design.png"
            alt="admin@bootstrapmaster.com"
          />
        </b-col>
        <b-col md="6" class="right">
          <b-card-group>
            <b-card-body class="background-mobile">
              <b-row align-v="center">
                <b-col class="text-center">
                  <img
                    src="../../assets/img/gramedia_digital.png"
                    alt="admin@bootstrapmaster.com"
                    class="m-5"
                  />
                </b-col>
              </b-row>
              <h1 class="h2 text-center text-primary">
                Digital Books Gramedia
              </h1>
              <h5 class="text-center text-danger">
                You are currently using the default password.
              </h5>
              <h5 class="text-center text-danger">
                Please, change your default password with new password.
                <p>And then, Login with new password !</p>
              </h5>
              <div>
                <div>
                  <b-form-group class="mt-5 mb-0">
                    <b-form-input
                      type="password"
                      class="form-control"
                      placeholder="type new password..."
                      required
                      v-model="password"
                    />
                  </b-form-group>
                </div>
                <div class="my-3">
                  <b-col>
                    <b-row>
                      <b-button
                        type="submit"
                        variant="primary"
                        class="px-4 w-100 mt-4"
                        :disabled="isDisable"
                        @click="changePassword"
                      >
                        Change Password
                        <i class="fa fa-sign-in" aria-hidden="true"></i>
                        <!-- <b-spinner type="grow" label="Spinning"></b-spinner> -->
                      </b-button>
                    </b-row>
                  </b-col>
                </div>
              </div>
            </b-card-body>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

//
<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'twoFactor',
  data() {
    return {
      password: '',
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Change Password | Content Management System Ebooks Gramedia.com';
      },
    },
    success: function() {
      if (!this.success) return;
      this.alertMsg(this.message, 'success');
      this.$router.push('/');
    },
    isError: function() {
      if (!this.isError) return;
      this.alertMsg(this.message, 'error');
    },
  },
  computed: {
    ...mapState({
      success: (state) => state.forgetPassword.isSuccess,
      message: (state) => state.forgetPassword.successMessage,
      isError: (state) => state.forgetPassword.isError,
    }),
    isDisable() {
      return !(this.password && this.password.length > 7);
    },
  },
  methods: {
    ...mapActions('forgetPassword', ['resetPassword']),
    async changePassword() {
      const payload = {
        newPassword: this.password,
        data: this.$route.params.token,
      };
      this.resetPassword(payload);
    },

    alertMsg(msg, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: msg,
        timer: 10000,
      });
    },
  },
};
</script>
<style lang="css" scoped>
.center {
  width: 500px;
  height: 400px;
  top: 20%;
  position: absolute;
  top: 20%;
  margin-left: auto;
  margin-right: auto;
}
.form-control {
  border: 0;
  border-bottom: 1px solid #000;
  outline: none;
}
input.form-control:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
@media (min-width: 360px) and (max-width: 768px) {
  .background-mobile {
    background-color: #fff;
  }
  .display-mobile {
    display: none;
  }
}
</style>
